.k-tilelayout {
    background-color: #ffedb1;
}

/*remoe headers*/
.k-tilelayout-item-header.k-card-header {
    display: none;
}

.k-tilelayout .k-card {
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.k-tilelayout {
    border-radius: 15px;
}

/* changed in order to remove padding from sides */
.k-tilelayout .k-card {
    /* padding: 0.2em 0.2em; */
}

.k-tilelayout.k-grid-flow-col {
    /* margin: 10px; */
    padding: unset;
}

.k-tilelayout-item.k-card {
    /* padding: 0px; */
}

.k-tilelayout-item {
    /* box-shadow:inset 10px black !important; */
}
