@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    box-sizing: border-box;
}
/* html, body, #app, #app>div {
   height: 100vh;
 } */
html,
body {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Rubik, Arial, Helvetica, sans-serif;
    color: #494949;
    max-height: 100vh;
    overflow: hidden;
    /* overflow: auto; */
}
p {
    margin: 0;
    padding: 0;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100%;
    overflow-y: inherit;
}

.MuiSelect-select.MuiSelect-select {
    text-align: initial;
}

*[dir='ltr'] {
    direction: ltr;
    text-align: left;
    unicode-bidi: embed;
}

*[dir='rtl'] {
    direction: rtl;
    text-align: right;
    unicode-bidi: embed;
}
