.App {
    text-align: center;
    height: 100%;
    /* max-width: 100%; */
    display: flex;
    flex-direction: column;
}

/* DO NOT REMOVE */
.k-height-container>* {
    height: 100% !important;
}

.MuiInputBase-input.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.034);
}

.de-activ:not(.ignore-de-activ *) {
    opacity: 0.2;
    pointer-events: none;
}

.hidden-scrollbar::-webkit-scrollbar {
    width: 0px;
    visibility: hidden;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 5px grey;
}

::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

@media all and (max-width: 1280px) {
    .hidden-wl-1280 {
        display: none !important;
    }

    /* hide it on larger screens */
}

.show-on-hover {
    visibility: hidden;
}

.show-on-hover:hover {
    visibility: visible;
}

.DatePicker .Mui-error.MuiFormHelperText-filled {
    display: none;
}

.input-ltr .MuiInputBase-input {
    text-align: left;
    direction: ltr;
}

.Mui-selected .MuiTab-wrapper {
    font-weight: bold;
}

/* from reports */
.css-6hp17o-MuiList-root-MuiMenu-list {
    padding: 0 !important;
    margin: 0;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    margin-top: 10px;
    border-radius: 2px !important;
}

.download-table-xls-button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    font-family: 'Rubik';
    font-size: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2196f3;
}

.no-shrink {
    flex-shrink: 0;
}