@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
    box-sizing: border-box;
}
/* html, body, #app, #app>div {
   height: 100vh;
 } */
html,
body {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Rubik, Arial, Helvetica, sans-serif;
    color: #494949;
    max-height: 100vh;
    overflow: hidden;
    /* overflow: auto; */
}
p {
    margin: 0;
    padding: 0;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100%;
    overflow-y: inherit;
}

.MuiSelect-select.MuiSelect-select {
    text-align: left;
    text-align: initial;
}

*[dir='ltr'] {
    direction: ltr;
    text-align: left;
    unicode-bidi: embed;
}

*[dir='rtl'] {
    direction: rtl;
    text-align: right;
    unicode-bidi: embed;
}

.App {
    text-align: center;
    height: 100%;
    /* max-width: 100%; */
    display: flex;
    flex-direction: column;
}

/* DO NOT REMOVE */
.k-height-container>* {
    height: 100% !important;
}

.MuiInputBase-input.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.034);
}

.de-activ:not(.ignore-de-activ *) {
    opacity: 0.2;
    pointer-events: none;
}

.hidden-scrollbar::-webkit-scrollbar {
    width: 0px;
    visibility: hidden;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 5px grey;
}

::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

@media all and (max-width: 1280px) {
    .hidden-wl-1280 {
        display: none !important;
    }

    /* hide it on larger screens */
}

.show-on-hover {
    visibility: hidden;
}

.show-on-hover:hover {
    visibility: visible;
}

.DatePicker .Mui-error.MuiFormHelperText-filled {
    display: none;
}

.input-ltr .MuiInputBase-input {
    text-align: left;
    direction: ltr;
}

.Mui-selected .MuiTab-wrapper {
    font-weight: bold;
}

/* from reports */
.css-6hp17o-MuiList-root-MuiMenu-list {
    padding: 0 !important;
    margin: 0;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    margin-top: 10px;
    border-radius: 2px !important;
}

.download-table-xls-button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    font-family: 'Rubik';
    font-size: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2196f3;
}

.no-shrink {
    flex-shrink: 0;
}
@font-face {
    font-family: Salsa-Regular;
    src: url(/static/media/Salsa-Regular.6b18a6a8.ttf);
}

@font-face {
    font-family: Rubik;
    src: url(/static/media/Rubik-VariableFont_wght.ea640765.ttf);
}

.k-grid tbody td {
    white-space: pre;
}

/*set grid tr hight*/
.k-grid tbody tr {
    line-height: 29px;
    height: 20px;
}

.k-grid tbody tr:hover,
.k-grid tbody tr.k-state-hover {
    background: #ffedb1;
}

/* set background color on header*/
.k-widget.k-grid .k-grid-header {
    background: #e6f6fe;
}

/* grid alt row remove background color */
.k-grid tr.k-alt {
    background-color: unset;
}

/* grid td border */
.kendo-grid .k-grid tr td {
    border: 1px solid rgba(180, 190, 201, 0.303);
}

/* sort icone color */
.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
    color: black;
}

/*remove norecords border*/
.k-grid tr.k-grid-norecords td {
    border: unset !important;
}

.broken-header-col {
    display: flex;
    height: 32px;
    padding-right: 5px !important;
    position: relative;
    top: 3px;
}

.broken-header-col-main {
    height: 40px;
}

.k-grid-header .k-header>.k-link {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    /* overflow-wrap: break-word; */
}

.k-grid[dir='rtl'] td,
.k-rtl .k-grid td {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

tr.k-master-row.k-alt.k-state-selected {
    background-color: #ffff8c;
}

tr.k-master-row.k-state-selected {
    background-color: #ffff8c;

}

.location-cell-td {
    background-color: white !important;

}


/*no norecords css*/
.kendo-grid[no-rows] .k-grid-content.k-virtual-content div:first-child {
    height: 100%;
}

.kendo-grid[no-rows] .k-grid-content.k-virtual-content .k-grid-table {
    height: 100%;
}

.kendo-grid[no-rows] .k-grid-content.k-virtual-content .k-grid-norecords td {
    font-size: 25px;
    color: rgba(73, 73, 73, 0.3);
    font-family: 'Rubik';
}

.kendo-grid .k-checkbox:checked,
.k-checkbox.k-checked {
    border-color: rgb(33, 150, 243);
    background-color: rgb(33, 150, 243);
}

.isSelected-col>input {
    position: relative;
    right: 2px;
}

.k-grid-norecords {
    pointer-events: none;
}
td.person-kendo-header {
    text-align: center;
}

.line-grid-container thead tr[role='row'] th:nth-child(2) {
    border-top-right-radius: 0.3em;
}

.line-grid-container tr[role='row'] td:nth-last-child(2) {
    border-left: none;
}

.passengersGrid tr[role='row'] td:nth-last-child(2) {
    border-left: none;
}

/* .line-grid-container thead tr[role='row'] {
    background-color: white !important;
    border-radius: none !important;
} */

/* .line-grid-container .k-grid-header {
    background: white !important;
}

.line-grid-container thead tr[role='row'] th {
    background-color: #e6f6fe;
} */

.k-grid-table tr td.Line-status-column {
    padding: 0px;
}

.k-link {
    /* border: none !important; */
}

.line-grid .k-grid-header .k-sort-order {
    display: none;
    border-bottom: none;
}

.k-grid-header {
    border-bottom: none;
}

.k-info-cell-header {
    background-color: white !important;
    border: none !important;
}

.info-cell-td {
    background-color: white !important;
    pointer-events: none;
}

.k-actions-cell-header {
    border-right: none !important;
}

.optionsCell {
    border-right: none !important;
}
/* .k-grid-content {
    overflow: visible;
}

.k-grid-container {
    overflow: visible !important;
} */

.k-tilelayout {
    background-color: #ffedb1;
}

/*remoe headers*/
.k-tilelayout-item-header.k-card-header {
    display: none;
}

.k-tilelayout .k-card {
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.k-tilelayout {
    border-radius: 15px;
}

/* changed in order to remove padding from sides */
.k-tilelayout .k-card {
    /* padding: 0.2em 0.2em; */
}

.k-tilelayout.k-grid-flow-col {
    /* margin: 10px; */
    padding: unset;
}

.k-tilelayout-item.k-card {
    /* padding: 0px; */
}

.k-tilelayout-item {
    /* box-shadow:inset 10px black !important; */
}

.line-details .MuiToggleButton-root.Mui-selected {
    border: 1px solid #40a8e2 !important;
    background-color: transparent;
}

.line-details .MuiToggleButtonGroup-root button {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    padding: 5px;
}

.line-details .MuiFormControl-root {
    width: 40%;
}
.line-details .MuiOutlinedInput-adornedEnd {
    height: 2em;
}

.line-details .MuiFormLabel-root.MuiInputLabel-root {
    font-size: 1.4em;
    top: -3px;
}

.line-details .line-details .MuiInputBase-root {
    height: 2em;
}

.line-details .MuiToggleButtonGroup-root {
    height: 2em;
}

.non-box-shadow.MuiPaper-elevation1 {
    box-shadow: unset;
}

.Manual-Order .dropDown-small .MuiSelect-outlined.MuiSelect-outlined {
    padding-top: 5px;
    padding-bottom: 6px;
}

.Manual-Order .MuiToggleButtonGroup-root {
    width: 300px;
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
        0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.Manual-Order .MuiToggleButtonGroup-root button {
    border: unset;
}

.Manual-Order .MuiToggleButton-root.Mui-selected {
    color: #2196f3;
    background-color: transparent;
    font-weight: 700;
    border-bottom: 1px solid #2196f3;
}
@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.shake {
    animation: shake 0.2s;
}
.Manual-Order .collect-drop-column {
    height: 80px;
}

.Manual-Order .emplyee-column {
    height: 80px;
}

.Manual-Order .k-grid td {
    padding: 2px;
}

.emplyee-column .k-grid td {
    padding: 2px;
}

/* table select boxes styling */
.k-header:first-of-type {
    text-align: center;
    padding: 0 0 4px 0;
}
td[role='gridcell'] {
    padding: 0 5px 0 0;
    margin: 0;
    /* text-align: center; */
}

th,
.k-header {
    text-align: right;
}
th > .MuiBox-root {
    font-size: 14px;
    @media (max-width: 1600px) {
        font-size: 12px;
    }
}
.k-grid-header .k-header > .k-link {
    font-size: 14px;
    @media (max-width: 1600px) {
        font-size: 12px;
    }
}
.k-grid th {
    padding-right: 5px;
    font-size: 14px;
}


td.person-kendo-header {
    text-align: center;
}

.k-grid-table tr td.Line-status-column {
    padding: 0px;
}

.line-grid .k-grid-header .k-sort-order {
    display: none;
}
.k-grid-header .k-header > .k-link {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
}

