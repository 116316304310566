td.person-kendo-header {
    text-align: center;
}

.line-grid-container thead tr[role='row'] th:nth-child(2) {
    border-top-right-radius: 0.3em;
}

.line-grid-container tr[role='row'] td:nth-last-child(2) {
    border-left: none;
}

.passengersGrid tr[role='row'] td:nth-last-child(2) {
    border-left: none;
}

/* .line-grid-container thead tr[role='row'] {
    background-color: white !important;
    border-radius: none !important;
} */

/* .line-grid-container .k-grid-header {
    background: white !important;
}

.line-grid-container thead tr[role='row'] th {
    background-color: #e6f6fe;
} */

.k-grid-table tr td.Line-status-column {
    padding: 0px;
}

.k-link {
    /* border: none !important; */
}

.line-grid .k-grid-header .k-sort-order {
    display: none;
    border-bottom: none;
}

.k-grid-header {
    border-bottom: none;
}

.k-info-cell-header {
    background-color: white !important;
    border: none !important;
}

.info-cell-td {
    background-color: white !important;
    pointer-events: none;
}

.k-actions-cell-header {
    border-right: none !important;
}

.optionsCell {
    border-right: none !important;
}
/* .k-grid-content {
    overflow: visible;
}

.k-grid-container {
    overflow: visible !important;
} */
