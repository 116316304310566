.k-grid tbody td {
    white-space: pre;
}

/*set grid tr hight*/
.k-grid tbody tr {
    line-height: 29px;
    height: 20px;
}

.k-grid tbody tr:hover,
.k-grid tbody tr.k-state-hover {
    background: #ffedb1;
}

/* set background color on header*/
.k-widget.k-grid .k-grid-header {
    background: #e6f6fe;
}

/* grid alt row remove background color */
.k-grid tr.k-alt {
    background-color: unset;
}

/* grid td border */
.kendo-grid .k-grid tr td {
    border: 1px solid rgba(180, 190, 201, 0.303);
}

/* sort icone color */
.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
    color: black;
}

/*remove norecords border*/
.k-grid tr.k-grid-norecords td {
    border: unset !important;
}

.broken-header-col {
    display: flex;
    height: 32px;
    padding-right: 5px !important;
    position: relative;
    top: 3px;
}

.broken-header-col-main {
    height: 40px;
}

.k-grid-header .k-header>.k-link {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    /* overflow-wrap: break-word; */
}

.k-grid[dir='rtl'] td,
.k-rtl .k-grid td {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

tr.k-master-row.k-alt.k-state-selected {
    background-color: #ffff8c;
}

tr.k-master-row.k-state-selected {
    background-color: #ffff8c;

}

.location-cell-td {
    background-color: white !important;

}


/*no norecords css*/
.kendo-grid[no-rows] .k-grid-content.k-virtual-content div:first-child {
    height: 100%;
}

.kendo-grid[no-rows] .k-grid-content.k-virtual-content .k-grid-table {
    height: 100%;
}

.kendo-grid[no-rows] .k-grid-content.k-virtual-content .k-grid-norecords td {
    font-size: 25px;
    color: rgba(73, 73, 73, 0.3);
    font-family: 'Rubik';
}

.kendo-grid .k-checkbox:checked,
.k-checkbox.k-checked {
    border-color: rgb(33, 150, 243);
    background-color: rgb(33, 150, 243);
}

.isSelected-col>input {
    position: relative;
    right: 2px;
}

.k-grid-norecords {
    pointer-events: none;
}