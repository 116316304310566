.line-details .MuiToggleButton-root.Mui-selected {
    border: 1px solid #40a8e2 !important;
    background-color: transparent;
}

.line-details .MuiToggleButtonGroup-root button {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    padding: 5px;
}

.line-details .MuiFormControl-root {
    width: 40%;
}
.line-details .MuiOutlinedInput-adornedEnd {
    height: 2em;
}

.line-details .MuiFormLabel-root.MuiInputLabel-root {
    font-size: 1.4em;
    top: -3px;
}

.line-details .line-details .MuiInputBase-root {
    height: 2em;
}

.line-details .MuiToggleButtonGroup-root {
    height: 2em;
}

.non-box-shadow.MuiPaper-elevation1 {
    box-shadow: unset;
}
