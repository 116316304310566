.Manual-Order .dropDown-small .MuiSelect-outlined.MuiSelect-outlined {
    padding-top: 5px;
    padding-bottom: 6px;
}

.Manual-Order .MuiToggleButtonGroup-root {
    width: 300px;
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
        0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.Manual-Order .MuiToggleButtonGroup-root button {
    border: unset;
}

.Manual-Order .MuiToggleButton-root.Mui-selected {
    color: #2196f3;
    background-color: transparent;
    font-weight: 700;
    border-bottom: 1px solid #2196f3;
}
@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.shake {
    animation: shake 0.2s;
}
.Manual-Order .collect-drop-column {
    height: 80px;
}

.Manual-Order .emplyee-column {
    height: 80px;
}

.Manual-Order .k-grid td {
    padding: 2px;
}

.emplyee-column .k-grid td {
    padding: 2px;
}

/* table select boxes styling */
.k-header:first-of-type {
    text-align: center;
    padding: 0 0 4px 0;
}
td[role='gridcell'] {
    padding: 0 5px 0 0;
    margin: 0;
    /* text-align: center; */
}

th,
.k-header {
    text-align: right;
}
th > .MuiBox-root {
    font-size: 14px;
    @media (max-width: 1600px) {
        font-size: 12px;
    }
}
.k-grid-header .k-header > .k-link {
    font-size: 14px;
    @media (max-width: 1600px) {
        font-size: 12px;
    }
}
.k-grid th {
    padding-right: 5px;
    font-size: 14px;
}
