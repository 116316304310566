td.person-kendo-header {
    text-align: center;
}

.k-grid-table tr td.Line-status-column {
    padding: 0px;
}

.line-grid .k-grid-header .k-sort-order {
    display: none;
}
.k-grid-header .k-header > .k-link {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
}
